.home {
    text-align: center;
    margin-bottom: 40px;
}

.home--image-container {
    margin: 50px 0px;
}

.home--image-container > img {
    width: 65%;
}

.home--button-flex {
    display: flex;
    justify-content: space-evenly;
    padding: 0px 200px;
    margin: 25px 0px 50px;
}

button {
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    background-color: #31b591;
}

/* .home {
    margin-bottom: 40px;
} */
.request--header {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.request--header > h4 {
    margin-left: 10px;
}

.request--details,
.form-group {
    padding-left: 100px;
}

.requestor,
.date {
    display: flex;
    justify-content: space-between;
    width: 200px;
}

.request--subheader > h5 {
    font-weight: 500;
}

.request--subheader,
.table {
    margin-left: 25px;
}

input,
select {
    margin-left: 25px;
    width: 250px;
    border: 1px solid #b8b2b2;
}

select {
    height: 28px;
}

sup {
    color: red;
}

label {
    margin: 15px 0px;
    width: 400px;
}

textarea {
    margin-left: 25px;
    border: 1px solid #b8b2b2;
}

.form-group {
    display: flex;
    align-items: baseline;
}

.form-group--column > textarea{
    margin-left: 0px;
    width: 430px;
}

i {
    font-size: 12px;
}

.checkbox--label {
    position: relative;
    left: 5%;
}

.form--checkboxes {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.form--checkboxes > div {
    margin: 5px 0px;
}

.form--checkboxes--input {
    width: auto;
}

.form-group--2 {
    justify-content: space-between;
    margin: 40px 0px;
}

.table th,
.table td {
    border-top: none;
    width: 100px;
}

.table {
    font-size: 15px;
    width: 98%;
}

.form-group--3 {
    padding-left: 25px;
}

.button-container {
    display: flex;
    justify-content: space-evenly;
    width: 200px;
    margin: 70px auto;
}